// DON'T CHANGE THESE EXISTING IMPORTS
// these were a PITA to get right, they create conflicts and duplicate imports if not done like this
const AuthenticationHelper =
  require("amazon-cognito-identity-js/lib/AuthenticationHelper").default;
const BigInteger = require("amazon-cognito-identity-js/lib/BigInteger").default;
const DateHelper = require("amazon-cognito-identity-js/lib/DateHelper").default;
const _global = require("aws-sdk/global");
import axios from "axios";
import Logging from "@/utils/Logging";
import { ChallengeType } from "@/types";

// IMPORTANT!
// Use the access_token to talk to the auth endpoints
// use the id_token to talk to the HIBT endpoints

//eslint-disable-next-line @typescript-eslint/no-explicit-any
type Error = any;

// TODO: type parameters arg
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Parameters = any;

type ChallengeResponse = {
  challenge: ChallengeType | undefined;
  parameters: Parameters;
  remedy?: string;
};

const AUTH_SERVER = process.env.NEXT_PUBLIC_AUTH_SERVER;
const POOL_NAME = process.env.NEXT_PUBLIC_POOL_NAME || "";

const OAUTH_PLATFORM =
  process.env.NODE_ENV === "development" ||
  process.env.CUSTOM_NODE_ENV === "development"
    ? "local"
    : "hibt";

// load deps
const authHelper = new AuthenticationHelper(POOL_NAME.split("_")[1]);
const dateHelper = new DateHelper();

// I don't like having these stored in a dict, we should do something to have a better temporary store
// or a better way to pass them around and reuse them

type Callback = (
  challengeCode: ChallengeType | undefined,
  parameters: Parameters
) => ChallengeResponse;

type UserInputs = {
  username: string;
  password: string;
  callback: Callback;
};

const user_inputs: UserInputs = {
  username: "",
  password: "",
  callback: () => {
    return { challenge: undefined, parameters: {} };
  },
};

//have to convert srp_b_o and salt
let username: string;

// exposed functions to call directly
const signup = async (
  username: string,
  email: string,
  password: string,
  callback: Callback
): Promise<ChallengeResponse> => {
  user_inputs.username = username;
  user_inputs.password = password;
  user_inputs.callback = callback;

  try {
    const response = await axios({
      method: "POST",
      url: `${AUTH_SERVER}/signup/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ username, email, password }),
    });

    const handleChallengeResponse = await handleChallenge(
      response.data.challenge,
      response.data.parameters
    );

    return handleChallengeResponse;
  } catch (error: Error) {
    Logging.error("signup", "authService", error.message);

    throw error;
  }
};

const login = async (
  username: string,
  password: string,
  callback: Callback
) => {
  try {
    user_inputs.username = username;
    user_inputs.password = password;
    user_inputs.callback = callback;

    return loginInit(username);
  } catch (error: Error) {
    Logging.error("login", "authService", error.message);

    throw error;
  }
};

const completeMFA = async (token: string, session: string) => {
  try {
    const response = await loginTokenMfa(token, session);

    return response;
  } catch (error: Error) {
    Logging.error("Error with completing MFA.", "authService", error.message);

    throw error;
  }
};

const completeNewPassword = async (new_password: string, session: string) => {
  await loginNewPasswordRequired(new_password, session);
};

const logout = async (access_token: string) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${AUTH_SERVER}/logout/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      data: JSON.stringify({}),
    });

    return response;
  } catch (error: Error) {
    Logging.error("Error with logging out.", "authService", error.message);

    throw error;
  }
};

// to do a social login, just send a user to this url:
// ${AUTH_SERVER}/social/google/
// they'll come back with a code argument, which should be submitted to this function
// probbaly need to come back to this
const SubmitGoogleLoginCode = async (code: string, callback: Callback) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${AUTH_SERVER}/social/google/callback/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ code }),
    });

    const callbackResponse = await callback(response.data, {});

    return callbackResponse;
  } catch (error: Error) {
    Logging.error("Error with google login.", "authService", error.message);

    throw error;
  }
};

// refresh token
const refreshAccessToken = async (username: string, refresh_token: string) => {
  let data = {};
  // the access token used for auth will periodically expire
  // so you should use this function to exchange a refresh token for an access token
  // should probably do this right at page load time, if we have one
  await axios({
    method: "POST",
    url: `${AUTH_SERVER}/refresh/`,
    headers: {
      Accept: "*/*",
      "Accept-Language": "en-US,en;q=0.9",
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ username, refresh_token }),
  })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      Logging.error("Error with logging out.", "authService", error.message);
    });

  return data;
};

const getUserInfo = async (access_token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${AUTH_SERVER}/userinfo/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    });

    return response;
  } catch (error: Error) {
    Logging.error("Error with getting userinfo", "authService", error.message);
    throw error;
  }
};

const updateUserInfo = async (
  access_token: string,
  preferred_username: string
) => {
  try {
    const response = await axios({
      method: "PUT",
      url: `${AUTH_SERVER}/userinfo/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      data: JSON.stringify({ preferred_username }),
    });

    return response;
  } catch (error: Error) {
    Logging.error("Error with updating userInfo", "authService", error.message);
    throw error;
  }
};

const resetPasswordRequest = async (username: string) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${AUTH_SERVER}/forgot_password/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ username }),
    });

    return response.data;
  } catch (error: Error) {
    Logging.error(
      "Error with requesting resetting password",
      "authService",
      error.message
    );
    throw error;
  }
};

const confirmResetPasswordRequest = async (
  username: string,
  new_password: string,
  confirmation_code: string
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${AUTH_SERVER}/forgot_password/confirm/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        username,
        confirmation_code,
        password: new_password,
      }),
    });

    return response.data;
  } catch (error: Error) {
    Logging.error(
      "Error with resetting password",
      "authService",
      error.message
    );

    throw error;
  }
};

const changePassword = async (
  access_token: string,
  current_password: string,
  new_password: string
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${AUTH_SERVER}/change_password/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Authorization: access_token,
      },
      data: JSON.stringify({
        current_password,
        new_password,
      }),
    });

    return response;
  } catch (error: Error) {
    Logging.error("Error with changing password", "authService", error.message);

    throw error;
  }
};

// these functions should be called automatically and don't need to be called directly

// // TODO: type parameters arg
// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// const handleChallenge = async (
//   challengeCode: ChallengeType,
//   parameters: any
// ): Promise<ChallengeResponse> => {
//   // this func directs logic around to the appropriate next step
//   if (challengeCode === "COMPLETE") {
//     // this means we are done, the parameters will be printed
//     // we have an access token to use for each request, but hang on to
//     // the refresh token, cuz we'll need it to get new access tokens

//     // calls callback with the auth tokens
//     const callbackResponse = await user_inputs.callback(
//       challengeCode,
//       parameters
//     );

//     return callbackResponse;
//   } else if (challengeCode === "PASSWORD") {
//     const passwordResponse = await loginPassword(
//       user_inputs.username,
//       user_inputs.password
//     );

//     return passwordResponse;
//   } else if (challengeCode === "SRP") {
//     const loginSRPResponse = await loginSRP(user_inputs.username);

//     return loginSRPResponse;
//   } else if (challengeCode === "SRP_VERIFY") {
//     const loginSRPVerifyResponse = await loginSRPVerify(
//       parameters,
//       user_inputs.password
//     );

//     return loginSRPVerifyResponse;
//   }

//   if (challengeCode === "SOFTWARE_TOKEN_MFA") {
//     // calls callback with challenge name and params
//     const softwareTokenMFAResponse = await user_inputs.callback(
//       challengeCode,
//       parameters
//     );

//     return softwareTokenMFAResponse;
//   } else if (challengeCode === "NEW_PASSWORD_REQUIRED") {
//     // calls callback with challenge name and params
//     const newPasswordRequiredResponse = await user_inputs.callback(
//       challengeCode,
//       parameters
//     );

//     return newPasswordRequiredResponse;
//   }
// };

const handleChallenge = async (
  challengeCode: ChallengeType,
  parameters: Parameters
): Promise<ChallengeResponse> => {
  switch (challengeCode) {
    case ChallengeType.COMPLETE:
      return user_inputs.callback(challengeCode, parameters);

    case ChallengeType.PASSWORD:
      return loginPassword(user_inputs.username, user_inputs.password);

    case ChallengeType.SRP:
      return loginSRP(user_inputs.username);

    case ChallengeType.SRP_VERIFY:
      return loginSRPVerify(parameters, user_inputs.password);

    case ChallengeType.SOFTWARE_TOKEN_MFA:
      return user_inputs.callback(challengeCode, parameters);

    case ChallengeType.NEW_PASSWORD_REQUIRED:
      return user_inputs.callback(challengeCode, parameters);

    default:
      throw new Error(`Unhandled challenge type: ${challengeCode}`);
  }
};

// login flows

const loginInit = async (username: string) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${AUTH_SERVER}/login/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ username, challenge: "INIT", parameters: {} }),
    });

    const challengeResponse = await handleChallenge(
      response.data.challenge,
      response.data.parameters
    );

    return challengeResponse;
  } catch (error: Error) {
    Logging.error("loginInit", "authService", error.message);
    throw error; // Ensure the error is propagated for handling outside the function
  }
};

const loginPassword = async (
  username: string,
  password: string
): Promise<ChallengeResponse> => {
  try {
    const response = await axios({
      method: "POST",
      url: `${AUTH_SERVER}/login/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        username,
        challenge: "PASSWORD",
        parameters: { password },
      }),
    });

    const handleChallengeResponse = await handleChallenge(
      response.data.challenge,
      response.data.parameters
    );

    console.log("handleChallengeResponse", handleChallengeResponse);

    return handleChallengeResponse;
  } catch (error: Error) {
    Logging.error(
      "Error logging in with password.",
      "authService",
      error.message
    );

    throw error;
  }
};

const loginSRP = async (username: string): Promise<ChallengeResponse> => {
  const generate_a = () => {
    return new Promise((resolve, reject) => {
      authHelper.getLargeAValue((err: Error, largeA: bigint) => {
        if (err) {
          reject(err);
        } else {
          resolve(largeA.toString(16));
        }
      });
    });
  };

  const srp_a = await generate_a();

  try {
    const srpData = await axios({
      method: "POST",
      url: `${AUTH_SERVER}/login/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        username,
        challenge: "SRP",
        parameters: { srp_a },
      }),
    });

    const challengeResponse = await handleChallenge(
      srpData.data.challenge,
      srpData.data.parameters
    );

    return challengeResponse;
  } catch (error: Error) {
    Logging.error("loginSRP", "authService", error.message);

    throw error;
  }
};

// TODO: type parameters arg
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loginSRPVerify = async (srp_parameters: any, user_password: string) => {
  const generateSignature = (
    hkdfValue: number[],
    username: string,
    secretBlock: string,
    userPoolId: string,
    dateNow: string
  ) => {
    return _global.util.crypto.hmac(
      hkdfValue,
      _global.util.buffer.concat([
        new _global.util.Buffer(userPoolId.split("_")[1], "utf8"),
        new _global.util.Buffer(username, "utf8"),
        new _global.util.Buffer(secretBlock, "base64"),
        new _global.util.Buffer(dateNow, "utf8"),
      ]),
      "base64",
      "sha256"
    );
  };

  const solveChallenge = async (
    username: string,
    password: string,
    srpB: string,
    salt: string,
    secretBlock: string,
    userPoolId: string
  ) => {
    try {
      const hkdfValue: number[] = await new Promise((resolve, reject) => {
        authHelper.getPasswordAuthenticationKey(
          username,
          password,
          srpB,
          salt,
          (err: Error, key: number[]) => {
            err ? reject(err) : resolve(key);
          }
        );
      });

      console.log("hkdfValue", hkdfValue);

      const dateNow = dateHelper.getNowString();
      const signatureString = generateSignature(
        hkdfValue,
        username,
        secretBlock,
        userPoolId,
        dateNow
      );
      return {
        username,
        timestamp: dateNow,
        signature: signatureString,
        secret_block: secretBlock,
      };
    } catch (error: Error) {
      Logging.error(
        `Error in solveChallenge: ${error.message}`,
        "authService",
        error.message
      );

      throw error; // Make sure to rethrow the error so it can be caught outside
    }
  };

  const srpBO = new BigInteger(srp_parameters.SRP_B, 16);
  const saltO = new BigInteger(srp_parameters.SALT, 16);
  const username = srp_parameters.USERNAME;
  const secretBlock = srp_parameters.SECRET_BLOCK;

  try {
    const challengeResponses = await solveChallenge(
      username,
      user_password,
      srpBO,
      saltO,
      secretBlock,
      POOL_NAME
    );

    const response = await axios.post(
      `${AUTH_SERVER}/login/`,
      {
        username,
        challenge: "SRP_VERIFY",
        parameters: challengeResponses,
      },
      {
        headers: {
          Accept: "*/*",
          "Accept-Language": "en-US,en;q=0.9",
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
        },
      }
    );

    return await handleChallenge(
      response.data.challenge,
      response.data.parameters
    );
  } catch (error: Error) {
    Logging.error("loginSRPVerify", "authService", error.message);
    throw error; // Ensure the error is propagated for handling outside the function
  }
};

const loginTokenMfa = async (token: string, session: string) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${AUTH_SERVER}/login/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        username,
        challenge: "SOFTWARRE_TOKEN_MFA",
        parameters: { token, session },
      }),
    });

    const handleChallengeResponse = await handleChallenge(
      response.data.challenge,
      response.data.parameters
    );

    return handleChallengeResponse;
  } catch (error: Error) {
    Logging.error("Error submitting 2fa token.", "authService", error.message);

    throw error;
  }
};

const loginNewPasswordRequired = async (
  new_password: string,
  session: string
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${AUTH_SERVER}/login/`,
      headers: {
        Accept: "*/*",
        "Accept-Language": "en-US,en;q=0.9",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        username,
        challenge: "NEW_PASSWORD_REQUIRED",
        parameters: { password: new_password, session },
      }),
    });

    const handleChallengeResponse = await handleChallenge(
      response.data.challenge,
      response.data.parameters
    );

    return handleChallengeResponse;
  } catch (error: Error) {
    Logging.error(
      "Error submitting new password.",
      "authService",
      error.message
    );

    throw error;
  }
};

const getOAuthUrl = (provider: string) => {
  return `${AUTH_SERVER}/social/${provider}/?platform=${OAUTH_PLATFORM}`;
};

const exchangeOAuthtoken = async (code: string, provider: string) => {
  let response = {};
  await axios({
    method: "POST",
    url: `${AUTH_SERVER}/social/${provider}/callback/`,
    headers: {
      Accept: "*/*",
      "Accept-Language": "en-US,en;q=0.9",
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ code, platform: OAUTH_PLATFORM }),
  })
    .then((res) => {
      response = res.data;
    })
    .catch((error: Error) => {
      Logging.error(
        "Error with exchanging oauth token.",
        "authService",
        error.message
      );
      response = error;
    });
  return response;
};

// for MFA setup required at login. I think ok to skip for now
// function loginMfaSetup() {}

// function loginMfaVerify() {}

const AuthService = {
  signup: signup,
  login: login,
  completeMFA: completeMFA,
  completeNewPassword: completeNewPassword,
  logout: logout,
  SubmitGoogleLoginCode: SubmitGoogleLoginCode,
  refreshAccessToken: refreshAccessToken,
  getUserInfo: getUserInfo,
  updateUserInfo: updateUserInfo,
  resetPasswordRequest: resetPasswordRequest,
  confirmResetPasswordRequest: confirmResetPasswordRequest,
  changePassword: changePassword,
  exchangeOAuthtoken: exchangeOAuthtoken,
  getOAuthUrl: getOAuthUrl,
};

export default AuthService;
